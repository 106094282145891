import React, { useEffect, useState } from "react"
import { Button, Radio } from "antd"
import { Col, Row } from "react-bootstrap"
import { LocalizedMatch } from "../../../types/match"
import { MatchDateSelector } from "@components/Match/MatchDateSelector"
import { Textfit } from "react-textfit"
import { MatchAccordions } from "@components/Match/MatchAccordions"
import { MatchAPI } from "../../../apis/MatchAPI"
import Link from "next/link"

export function MatchesDate({
  matches: allMatches,
  timeout = 35 * 1000,
  reload = true,
  maxGroup,
  viewAllButton = false,
}: {
  matches: LocalizedMatch[]
  timeout?: number
  reload?: boolean
  viewAllButton?: boolean
  maxGroup?: number
}) {
  const [date, setDate] = useState<string | undefined>(undefined)
  const [matches, setMatches] = useState<LocalizedMatch[]>(allMatches)
  const [loading, setLoading] = useState<boolean>(false)
  const [internalInterval, setInternalInterval] = useState<NodeJS.Timer | undefined>(undefined)

  const [matchType, setMatchType] = useState<"all" | "live" | "ended" | "future">("all")
  const [nearestDate, setNearestDate] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (date) {
      setLoading(true)

      if (internalInterval) {
        clearInterval(internalInterval)
      }

      let onlyLive = false
      let onlyPlayed = false
      let onlyFuture = false

      switch (matchType) {
        case "ended":
          onlyPlayed = true
          break

        case "future":
          onlyFuture = true
          break

        case "live":
          onlyLive = true
          break
      }

      MatchAPI.getPublicMatchesByDate(date, onlyLive, onlyPlayed, onlyFuture)
        .then((matches) => {
          setMatches(matches)

          if (reload) {
            const interval = setInterval(() => {
              MatchAPI.getPublicMatchesByDate(date, onlyLive, onlyPlayed, onlyFuture)
                .then((matches) => {
                  setMatches(matches)
                })
                .catch(() => {
                  return
                })
            }, timeout)

            setInternalInterval(interval)
          }
        })
        .catch(() => {
          return
        })
        .finally(() => {
          setLoading(false)
        })
    }

    if (internalInterval) {
      return () => clearInterval(internalInterval)
    }
  }, [date, matchType])

  useEffect(() => {
    MatchAPI.getNextDateWithMatches()
      .then((date) => {
        setNearestDate(date)
      })
      .catch(() => {
        return
      })
  }, [])

  return (
    <Col className={"matches-date"}>
      <Row className={"g-0"}>
        <Col lg={7} sm={12}>
          <Radio.Group
            className={"matches-date-radio"}
            onChange={(e) => {
              setMatchType(e.target.value)
            }}
            value={matchType}
            optionType="button"
          >
            {[
              { value: "all", label: "Tutte" },
              { value: "live", label: "Live" },
              { value: "ended", label: "Concluse" },
              { value: "future", label: "Programmate" },
            ].map(({ value, label }) => {
              return (
                <Radio.Button value={value} key={value}>
                  <Textfit mode={"single"} max={14}>
                    {label}
                  </Textfit>
                </Radio.Button>
              )
            })}
          </Radio.Group>
        </Col>
        <Col lg={2} sm={12} style={{ marginTop: "5px" }} />
        <Col lg={3} sm={12}>
          <MatchDateSelector onChange={setDate} />
        </Col>
      </Row>
      <Row className={"marginTop20"}>
        {matches.length > 0 ? (
          <MatchAccordions matches={matches} loading={loading} date={date} maxGroup={maxGroup} />
        ) : (
          <>
            <span className={"match-accordions-missing-data-message"}>Nessuna partita disponibile.</span>
            {nearestDate && (
              <Button
                onClick={() => {
                  setMatchType("all")
                  setDate(nearestDate)
                }}
              >
                Vai alla data più vicina con partite disponibili
              </Button>
            )}
          </>
        )}
      </Row>
      {viewAllButton && (
        <Row>
          <Col className="newButtonStyle">
            <Link href="/live-risultati" passHref>
              <a>VEDI TUTTI</a>
            </Link>
          </Col>
        </Row>
      )}
    </Col>
  )
}
