import React from "react"
import { LocalizedMatch, MatchLiveStatusValues } from "../../../types/match"
import { List } from "antd"
import { Col, Row } from "react-bootstrap"
import moment from "moment"
import { LocalizedTeam } from "../../../types/team"
import Image from "next/image"
import * as ImageConstants from "../../../constants/images"
import liveCircle from "@assets/images/live-circle.gif"
import { Textfit } from "react-textfit"
import { getMatchPageUrl } from "../../../utils/urls"
import "moment/locale/it"
import * as LanguageConstants from "../../../constants/languages"
import Link from "next/link"

moment.locale(LanguageConstants.DEFAULT_LOCALE)

function MatchListElementTeam({ team, score, live }: { team: LocalizedTeam; score: number | null; live: boolean }) {
  return (
    <Row className={"match-list-element-team"}>
      <Col xs={10} md={7} style={{ display: "flex" }}>
        <span className={"match-list-element-team-image"}>
          <Image
            src={team.image?.path || ImageConstants.TEAM_MISSING_IMAGE_PATH}
            alt={team.name}
            title={team.name}
            layout={"intrinsic"}
            width={15}
            height={15}
          />
        </span>
        <span>{team.name}</span>
      </Col>
      <Col
        xs={2}
        md={5}
        className={`match-list-element-team-result ${live ? "match-list-element-team-result-live" : ""}`}
      >
        {score === null ? " ‒ " : score}
      </Col>
    </Row>
  )
}

export function MatchListElement({ match }: { match: LocalizedMatch }) {
  let status = null

  if (match.date) {
    if (match.date.includes("22:00:00.000Z")) {
      status = moment(match.date).format("DD MMMM")
    } else {
      status = moment(match.date).format("HH:mm")
    }
  }

  let live = false
  let postponed = false

  if (MatchLiveStatusValues.includes(match.status || "")) {
    live = true

    if (match.status === "halfTime" || match.status === "breakTime") {
      status = "Intervallo"
    } else {
      if (match.currentMinute !== null) {
        status = `${match.currentMinute}'`
      } else {
        status = "Live"
      }
    }
  } else {
    live = false

    if (match.status === "postponed") {
      status = "Rinviata"
      postponed = true
    }
  }

  if (match.ended) {
    status = "Terminata"
  }

  return (
    <Link href={getMatchPageUrl(match.calciocomSlug!, live ? "diretta-live" : undefined)} passHref>
      <a>
        <List.Item className={"match-list-element"}>
          <Col title={"Vai al dettaglio partita"}>
            <Row className={"g-0"}>
              <Col
                xs={3}
                xl={2}
                className={`match-list-element-status ${live ? "match-list-element-status-live" : ""} ${
                  postponed ? "match-list-element-status-postponed" : ""
                }`}
              >
                <Textfit mode={"single"} max={15}>
                  {status}
                </Textfit>
              </Col>
              {match.homeTeam && match.awayTeam && (
                <Col xs={7} xl={9}>
                  <MatchListElementTeam team={match.homeTeam} score={match.homeScore} live={live} />
                  <MatchListElementTeam team={match.awayTeam} score={match.awayScore} live={live} />
                </Col>
              )}
              <Col xs={2} xl={1} className={"match-list-element-live-image"}>
                {live && <Image src={liveCircle.src} alt={"Live"} layout={"intrinsic"} width={25} height={25} />}
              </Col>
            </Row>
          </Col>
        </List.Item>
      </a>
    </Link>
  )
}
