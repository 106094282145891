import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { Select } from "antd"
import { Col, Row } from "react-bootstrap"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import moment from "moment"
import * as LanguageConstants from "../../../constants/languages"
import "moment/locale/it"
import { LabeledValue } from "antd/es/select"

moment.locale(LanguageConstants.DEFAULT_LOCALE)

const { Option } = Select

export function MatchDateSelector({
  onChange = () => {
    return
  },
}: {
  onChange: (date: string) => void
}) {
  const [currentValue, setCurrentValue] = useState<string>(moment().format("YYYY-MM-DD"))
  const [values, setValues] = useState<string[]>([])

  useEffect(() => {
    const newValues = []

    const nowMinus7 = moment(currentValue).subtract(7, "day")
    const nowPlus7 = moment(currentValue).add(8, "day")
    const now = nowMinus7

    while (now.diff(nowPlus7) !== 0) {
      newValues.push(now.format("YYYY-MM-DD"))
      now.add(1, "day")
    }

    setValues(newValues)

    onChange(currentValue)
  }, [currentValue])

  return (
    <Row className={"match-date-selector g-0"}>
      <Col xs={2}>
        <Button
          className={"match-date-selector-button"}
          icon={<LeftOutlined />}
          onClick={() => {
            setCurrentValue((value) => moment(value).subtract(1, "day").format("YYYY-MM-DD"))
          }}
        />
      </Col>
      <Col xs={8}>
        <Select
          labelInValue
          onChange={(value: LabeledValue) => {
            setCurrentValue(value.value as string)
          }}
          className={"match-date-selector-select"}
          value={{ value: currentValue, label: moment(currentValue).format("DD/MM ddd").toUpperCase() }}
        >
          {values.map((value) => {
            return (
              <Option value={value} key={value}>
                {moment(value).format("DD/MM ddd").toUpperCase()}
              </Option>
            )
          })}
        </Select>
      </Col>
      <Col xs={2}>
        <Button
          className={"match-date-selector-button"}
          icon={<RightOutlined />}
          onClick={() => {
            setCurrentValue((value) => moment(value).add(1, "day").format("YYYY-MM-DD"))
          }}
        />
      </Col>
    </Row>
  )
}
